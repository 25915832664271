@import url("https://use.typekit.net/fvk4nfp.css");

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Black.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-Bold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler-ExtraBold.woff2") format("woff2"),
    url("./Assets/Fonts/Butler-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("./Assets/Fonts/Butler.woff2") format("woff2"),
    url("./Assets/Fonts/Butler.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$color1:#007670;

$color1opacity30: #D3202B30;
$color1opacity42: #D3202B42;
$color1opacity60: #D3202B60;

$color2: #73B76F;
$color3: #343333;

$primaryfont: "lato";

body { background: #E5E5E5 ; font-family: "lato", sans-serif; color: $color3; margin: auto; overflow-x: hidden; }

.desktop { display: block; }
.mobile { display: none; }

header {
  & { background: #007670; height: 126px; width: 100%; }
  .header-wrapper { display: flex; align-items: center; justify-content: space-between; height: 126px; max-width: 1000px; width: 100%; margin: 0 auto; }
  .logo { 
    // & { position: absolute; left: 50px; cursor: default; }
    & { padding: 0 10px; width: 70px; }
    img { max-height: 52px; max-width: 312px; }
  }
  .assessment-title { 
    & { flex: 1 1 auto; text-align: center; color: #ffffff; }
    .logo-text { font-size: 36px; margin: 0 auto; font-weight: 700; line-height: 43px; }
    .logo-subtext { font-size: 21px; margin: 0 auto; font-weight: 700; line-height: 25px; letter-spacing: 8px; }
    img { height: 29px; max-width: 433px; margin-top: 5px; } 
  }
  // .bleat-logo { position: absolute; right: 50px; }
  .bleat-logo { padding: 0 10px; }
}

main {
  .container { margin: 80px auto; max-width: 1200px; border-radius: 10px; box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.0945336); }
  .container.results { margin: 30px auto 60px; }
  .loading { display: flex; justify-content: center; align-items: center; }
  
  .start { 
    & { height: 600px; width: 96%; background-color: #ffffff; display: flex; justify-content: center; }
    .video { 
      & { position: relative; width: 50%; background-color: #271C23; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
      // video { object-fit: cover; transform: scale(100%); max-width: 100%; }
      video { object-fit: cover; transform: scale(100%); }

      .thumbnail {  position: absolute; width: auto; height: 105%; overflow: hidden; object-fit: cover; }
      .caption { & { position: absolute; bottom: 0; text-align: center; width: 100%; background-color: #00000099; padding: 6px 0 12px; }
        p { margin: 2px auto; color: #FFFFFF; font-weight: 600; font-size: 18px; line-height: 22px; }
        p.name { color: rgba(107, 180, 136, 0.8); font-weight: 700; font-size: 24px; line-height: 29px; }
      }
      // .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; }
      .playbtn { position: absolute; top: 87%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: auto; width: 190px; border-radius: 25px;}
    }
    .intro {
      & { width: 46%; padding: 0 2%; display: flex; align-items: left; flex-direction: column; justify-content: center; transition: all 0.25s; }
      h1 { margin: 15px auto; font-family: "lato", sans-serif; font-weight: 700; font-size: 38px; color:$color3; line-height: 65px; max-width: 24ch; }
      // h2 { margin: 0; margin-bottom: 0; font-weight: 300; font-style: normal; color: $color3; font-size: 24px; line-height: 27px; max-width: 100%; max-width: 27ch; }
      .intro-points-list { padding-left: 30px; margin: 15px auto 30px; font-weight: 300; font-style: normal; color: #343333; font-size: 24px; line-height: 35px; max-width: 100%;}
      
      .completion { 
        & { display: flex; align-items: center; margin-bottom: 30px; color: #343333; font-weight: 700; font-size: 18px; }
        .cta { display: block; min-width: 180px; width: 95%; max-width: 200px; background-color: $color1; border-radius: 5px; color: #fff; text-decoration: none; text-align: center; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; text-transform: uppercase; padding: 12px 0 12px; margin-left: 0; }
        .cta:hover { background: $color2; transition: 0.3s; color: #fff; }
        .time-estimate {
          & { margin: 15px auto 15px 45px; }
          .clock-icon { width: 25px; height: 25px; vertical-align: bottom; }
        }
      }
    }
  }

  .assessment {
    & { min-height: 580px; background: #fff; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 20px; }
      .progress-percent-text { transition: 0.4s linear; font-family: $primaryfont; font-size: 21px; font-weight: 700; color: #007670; margin-top: 10px; margin-bottom: 0; line-height: 25px; width: 4ch; max-width: calc(100% - 3ch); }
      .progress-bar {
        & { display: flex; justify-content: space-between; width: 100%; margin-top: 16px; }
        .bar-section { 
          .progress-percentage { text-align: right; font-weight: 700; font-size: 1.1rem; margin: 1rem 0 0.5rem 1rem; }
          .progress-grid { 
            display: grid; grid-template-columns: repeat(3, 1fr); max-width: 42px; margin: 2.5rem auto;
            .grid-square { width: 10px; height: 10px; margin: 2px; } 
          }
          .bar { height: 6px; margin: 0 5px 10px; }
          .section-title { width: 100%; text-align: center; font-family: 'lato', sans-serif; font-weight: 400; color: #343333; font-size: 20px; }
        }

        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 6px; border-radius: 3px; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
      }

      .progress-indicator { margin: 70px auto 50px; width: 100%; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: #202945; font-size: 18px; }

      .question { min-height: 150px; max-width: 85%; margin: 0 auto; text-align: center; font-family: $primaryfont, sans-serif; font-weight: 400; color: $color3; font-size: 24px; }

      .radio-container {
        & { width: 86%; margin: 0 auto; }
        
        .slider-labels {
          & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .MuiRadio-root { margin-left: -20px; }
        
      }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; color: $color3; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; letter-spacing: 1px; }
            p.desktop { display: block; }
            p.mobile { display: none; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; width: calc(100% + 10px); margin-left: -5px; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background-color: #007670; padding: 10px; border: 3px solid #007670; }
        .custom-slider .MuiSlider-thumb:before { background: #007670; border: 4px solid white; width: 4px; height: 4px; padding: 2px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -1px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 120px auto 30px; padding-bottom: 30px; }
        button { color: #343333; font-weight: 600; font-size: 14px; font-family: "lato", sans-serif; }
      }
    }
  }

  .assessment-additional {
    & { min-height: 580px; background: #fff; border-radius: 10px; color: $color3; width: 95%; }
    .steps {
      & { margin: 0 auto 50px; width: 80%; min-height: 480px; }
      .spacer { height: 50px; }
      h1 { font-size: 18px; text-align: center; }

      .progress-indicator { margin: 2.5rem auto; width: 100%; text-align: center; font-family: "lato", sans-serif; font-weight: 400; color: $color1; font-size: 18px; }

      .question { max-width: 41ch; margin: 0 auto; min-height: 110px; text-align: center; font-family: "lato", sans-serif; font-weight: 400; font-size: 24px; }
      .textarea { min-height: 80px; }
      .textarea-field { max-width: 540px; width: 80%; margin: 0 auto; margin-bottom: 0; }
      .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 21px; border: 1px solid #979797; padding: 14px 18px; border-radius: 5px; }
      .form-details-text { background-color: #fff; padding: 0 9px 22px; height: 90px; }

      .slider-container {
        & { width: 86%; margin: 0 auto; }
        .slider { width: 100%; position: relative; }
        .slider-points {
          & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #182036; position: relative; }
          .point {
            & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -5px; background: #9b9b9b; position: absolute; z-index: 9; }
          }
        }
        .slider-labels {
          & { width: 125%; font-weight: 400; font-size: 21px; color: $color3; }
          .label {
            & { cursor: pointer; width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
          .active { font-weight: 700; color: $color1; }
        }

        .custom-slider.MuiSlider-root { z-index: 1000; color: $color1; }

        .custom-slider .MuiSlider-rail { opacity: 0; }
        .custom-slider .MuiSlider-track { border: 5px solid $color1; }

        .custom-slider .MuiSlider-thumb { background: $color1; }
        .custom-slider .MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
        .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
        
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
        .custom-bar .MuiLinearProgress-bar { background-color: $color1; top: -2px; }

        // .css-eglki6-MuiLinearProgress-root {
        //   background: #d8d8d8;
        // }
        // .css-5xe99f-MuiLinearProgress-bar1 {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb {
        //   background: $color1;
        // }
        // .css-eg0mwd-MuiSlider-thumb:before {
        //   background: #fff;
        //   border: 4px solid $color1;
        //   width: 14px;
        //   height: 14px;
        // }
        // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
        //   box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5);
        // }
        // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      }

      .slider-none-adjust { height: 35px; }
      .step-buttons {
        & { display: flex; justify-content: space-between; margin: 80px auto 30px; padding-bottom: 30px; }
        button { padding: 11px 10px; font-size: 20px;line-height: 29.2px; color: #182036; font-weight: 600; font-family: "lato", sans-serif; }
        // button:hover{background-color: #73B76F !important ;}
        .nextbtn { background: $color1; color: #fff; padding: 11px 10px; border-radius: 5px; font-weight: 700; }
        .nextbtn:hover { background-color: #73B76F  ; }
        .nextbtn.Mui-disabled { background-color: #D8D8D8; color: #979797; }
        .all-set-btn { padding: 11px 44px; }
      }
    }
  }

  .details {
    & { min-height: 630px; max-width: 1110px; width: 96%; background: #fff; }
    h1 { width: 100%; text-align: center; margin: 0 auto; font-family: "lato", sans-serif; font-size: 36px; color: $color3; padding: 32px 0 0; }
    p { text-align: center; font-size: 21px; font-weight: 400; line-height: 1.4em; color: $color3; }

    .form {
      & { display: flex; justify-content: center; flex-wrap: wrap; margin: 43px auto 0px auto; width: 60%; }
      .form-detail { 
        * { box-sizing: border-box; }
        & { position: relative; flex: 0 1 46%; max-width: 46%; display: block; margin-bottom: 20px; font-weight: 700; padding: 0 2%; /* overflow: hidden; */ }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .MuiInput-input { height: 28px; }
        .MuiSelect-select .first-menu { color: #fff; }
        
        // .form-details-text { background-color: #F4F4F4; padding: 15.6px 15px; padding: 10px 15px; max-width: 100%; }
        .form-details-text { background-color: #F4F4F4; max-width: 100%; border-radius: 3px; }
        .form-details-text .MuiInput-root { padding: 10px 15px; border-radius: 3px; border: 1px solid transparent; }
        .form-details-text .MuiInput-root.Mui-focused { border: 1px solid $color1; }
        
        
        .form-details-text.react-select div:nth-child(3) { border: none; }
        .form-details-text.react-select .react-select__placeholder { white-space: nowrap; overflow-x: hidden; padding-left: 4px; }
        .form-details-text.react-select { & { padding: 0; font-size: 18px; font-family: "lato", sans-serif; font-weight: 400; }
          div:first-child { padding-top: 6.25px; padding-bottom: 6.25px; }
        }

        .form-details-text input:focus::placeholder { color: transparent; font-weight: 300; }
        .form-details-text {
          input[type=number]::-webkit-inner-spin-button, 
          input[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
        }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 12px; }
        .required-field { color: #007670; }
        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #F4F4F4 inset !important;
        }
      }
      .form-detail:last-child.school-name {
        flex: 0 1 100%; max-width: 100%; width: 100%;
      }
    }

    .details-buttons { & { display: grid; grid-template-columns: 1fr 1fr 1fr; max-width: 80%; margin: 0 auto; padding-top: 16px; padding-bottom: 3rem; } 
      .back-btn { align-self: center; }
      .cta-btn {
        & { margin: 0 auto; text-decoration: none; align-self: center; }
        a { text-decoration: none; }
        button { width: 280px; background: $color1; font-family: "lato", serif; font-size: 21px; font-weight: 700; color: #fff; border-radius: 5px; padding: 8px 0; text-align: center; text-transform: uppercase; }
      button:hover { background: $color2; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
    }
  }

  .results {
    & { min-height: 660px; width: 96%; background-color: #fff; color: $color3; }
    .spacer { height: 20px; }

    .selection-form { 
      &  { width: 50%; display: flex; align-items: center; margin: 20px auto; flex-wrap: wrap; justify-content: center; text-align: center; font-size: 36px; font-weight: 400; }
      .form-detail { flex: 0 1 100%; margin-right: 4%;}
      .form-text { flex: 0 1 100%; }
      .form-details-text { 
        & { background-color: #fff; border: 0px solid #efefef; border-bottom: 4px solid $color1; padding: 3px 7px; max-width: 90%; }
        .MuiInput-root { font-size: 36px; font-family: "lato", sans-serif; }
      }
    }

    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', sans-serif; font-size: 30px; color: $color3; padding: 32px 32px 0; max-width: 58ch;
      .user-name { text-transform: capitalize; }
    }
    
    .progress-bar { margin: 90px auto 90px; position: relative; width: 90%; }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: $color1; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 680px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%); 
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { width: 3px; z-index: 1; height: 55px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color: #4A4A4A; bottom: 50px; left: 0; margin-left: -20px; }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    
    
    .pervalue h4 { position: relative; top: 45px; }
    .pervalue { max-width: 680px; margin: -68px auto; display: flex; clear: both; color: #4A4A4A; font-size: 16px; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: 35px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: 35px; }
    .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; top: 0px; }
    // .pervalue h4.nimp:after { content: "20%"; position: absolute; right: -19px; top: 0px; }
    .pervalue h4.mexpe:after { content: "50%"; position: absolute; right: -19px; top: 0px; }
    // .pervalue h4.eexpe:after { content: "60%"; position: absolute; right: -19px; top: 0px; }
    .pervalue h4.eexpe1:after { content: "80%"; position: absolute; right: -19px; top: 0px; }
    .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -8px; top: 0px; }
    // .pervalue-text h4.nimp:after { content: 'Not Aligned'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.mexpe:after { content: 'Needs Better Alignment'; position: absolute; right: -19px; bottom: -75px; }
    // .pervalue-text h4.eexpe:after { content: 'Aligned'; position: absolute; right: -19px; bottom: -75px; }
    .pervalue-text { & { position: relative; display: block; margin: 0 auto; width: 100%; max-width: 680px; bottom: -75px; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 16px; vertical-align: top; color: #575757; }
    }

    .si_pro_header { max-width:680px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; right: -3px; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 3px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 3px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe:before { width: 3px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe1:before { width: 3px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: $color1; line-height: 35px; font-size: 16px; }
    
    .result-text { text-align: left; font-size: 18px; margin: 20px auto; max-width: 94ch; background-color: #EEEEEE; padding: 15px 30px 15px; border-radius: 10px; color: #121212; width: 95%; max-width: 950px; box-sizing: border-box; }
    .result-text p { padding-top: 0; margin-block-start: 0; margin: 15px auto; max-width: 95%; }
    .result-sections { 
      & { margin: 50px 5%; display: flex; justify-content: center; flex-wrap: wrap; padding-bottom: 50px; }
    }
    .score-section-container {
      & { display: flex; flex-direction: row; justify-content: center; align-items: center; max-width: 90%; padding: 2rem 2rem 4rem 2rem; margin: 1rem auto; }
      .score-section { 
        & { flex: 0 1 25%; text-align: center; color: $color3; font-size: 18px; font-weight: 700; margin: 10px auto; }
        .score-txt { font-size: 21px; font-weight: 700; margin-bottom: 6px; }
        .score-name { font-size: 18px; font-weight: 700; margin: 0.25rem auto; text-transform: uppercase; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; }
        .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; width: 100%; }
        .custom-bar .MuiLinearProgress-bar { background-color: #2D8D95; }
      }
    }

    
    .selection-form .form-details-text .MuiInput-root .MuiSelect-select { padding-right: 0; }
    //.css-eglki6-MuiLinearProgress-root { background: #E9E9E9; height: 5px; }
    //.css-5xe99f-MuiLinearProgress-bar1 { background: $color1; height: 5px; }

    .members-answered {
      & { width: 96%; max-width: calc(880px - 10%); background-color: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 8px; }
      .section {
        & { background-color: $color1; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; display: flex; align-items: center; justify-content: space-between; cursor: pointer; }
        .section-toggle { display: block; cursor: pointer; height: 24px; }
      }
      .answered-detail { 
        & { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px); display: flex; align-items: center; }        
        .answer { flex: 1 1 285px; margin-left: 10px; text-align: right; font-weight: 700; }
        p { margin: 0; }
        .true { color: #73B76F; }
        .neutral { color: #FFB545; }
        .false { color: #FF6645; }
      }
      .answered-detail:nth-child(even) { background-color: #f4f4f4; }      
    }

  }

  .invite {
    & { min-height: 380px; width: 96%; max-width: 1110px; padding-bottom: 30px; background: #fff; }
    h1 { width: auto; text-align: center; margin: 0 auto 25px; font-family: "lato", sans-serif; font-size: 36px; color: $color3; padding: 67px 15px 0; }
    p { text-align: center; margin: 5px auto 10px; padding: 0 20px; font-size: 21px; line-height: 1.4em; color: #202020; max-width: 55ch; }
    p:nth-child(3) { margin-bottom: 35px; }
    .form {
      & { position: relative; display: flex; justify-content: space-between; flex-wrap: wrap; margin: 10px auto; width: 49%; }
      .invite-number { position: absolute; left: -50px; top: 43px; font-weight: bold; color: #C2C2C2; }
      .form-detail {
        & { flex: 0 1 46%; display: block; margin-bottom: 5px; font-weight: 700; }
        p { padding: 0; margin-bottom: 5px; text-align: left; font-size: 16px; font-weight: 700; }
        .MuiInput-root { font-family: 'lato', sans-serif !important; font-size: 18px; font-weight: 400; }
        .invite-field { font-family: 'lato', sans-serif !important; background-color: #F4F4F4; }
        .invite-field .MuiInput-root { border: 1px solid transparent; padding: 5px 10px; border-radius: 3px; }
        .invite-field .MuiInput-root.Mui-focused { border: 1px solid $color1; }
        .invite-field::placeholder { font-family: 'lato', sans-serif !important; font-size: 18px !important; color: #9B9B9B; font-weight: 300; }
        .invite-field input:focus::placeholder { color: transparent; }
        .error-text { position: absolute; bottom: -20px; color: red; font-weight: 400; font-size: 13px; }
      }      
      .delete-btn { 
        & { position: absolute; right: -58px; top: 40px; }
        .delete-icon { color: #D5D5D5; font-size: 24px; cursor: pointer; }
      }
    }

    .invite-another-person {
      & { margin: 0 auto; width: 55%; position: relative; height: 5px; }
      .invite-another { & { width: 74px; background-color: $color2; position: absolute; top: -57px; cursor: pointer; right: -130px; border-radius: 5px; text-align: center; padding: 2px 6px; color: #fff; }
        .add-btn { font-size: 18px; padding: 0 6px 0 0; color: #fff; font-weight: 700; font-family: $primaryfont; }
        .invite-another-icon { vertical-align: sub; }
      }
    }

    .cta-btn {
      & { display: block; margin: 30px auto 50px; text-decoration: none; width: 185px; background: $color1; color: #fff; font-family: "lato", serif; border-radius: 5px; font-size: 21px; padding: 8px 0; text-align: center; text-transform: uppercase; font-weight: bold; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      .button-progress { margin: 4px 0 -2px; }
    }
  }

  .thank-you {
    & { min-height: 380px; max-width: 926px; width: 96%; padding-bottom: 30px; background-color: #fff; }
    .tick { & { text-align: center; padding: 48px 0 0; }
      img { width: 57px; height: 57px; }
    }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: "lato", serif; font-size: 36px; color: #1f2a44; padding: 24px 20px 15px; }
    p { width: 65%; margin: 0 auto; text-align: center; font-size: 21px; color: #202020; }
    .back-btn { 
      & { text-align: center; margin: 3rem auto 3rem auto; }
      a { border-bottom: 2px solid $color1; text-transform: uppercase; font-weight: bold; cursor: pointer; text-decoration: none; color: #202020; }
    }
  }

  .invite-container {
    & { width: 96%; box-sizing: border-box; margin: 50px auto; padding: 0 5% 30px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #FFFFFF; text-align: center; }
    h3 { font-weight: 700; font-size: 36px; line-height: 1.2; }
    p { max-width: 60ch; margin: 1rem auto;}
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 30ch; margin: 0 auto; }
        span { color: #C86F3A; font-weight: 700; }
        img { height: 61px; }
      }
    }
    .cta-btn {
      & { display: inline-block; margin: 40px auto 0; color: #fff; padding: 0; border: 3px solid #ffffff; border-radius: 35px; }
      &:hover { background: #73B76F; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: auto; display: inline-block; padding: 5px 25px; color: #fff; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; height: 50px; border-radius: 35px; }
      button:hover { background: #73B76F; transition: 0.3s; color: #fff; }
    }
  }

  .invite-container-new {
    & { background: #FFFFFF; border-radius: 10px; width: 95%; max-width: 980px; box-sizing: border-box; margin: 50px auto; padding: 30px 5% 30px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #202945; text-align: center; box-shadow: 5px 5px 5px #12121240; }
    h3 { font-weight: 700; font-size: 30px; line-height: 1.2; max-width: 45ch; margin: 30px auto 30px; }
    p { max-width: 65ch; margin: 1rem auto;}
    .height30 { height: 30px; }
    .invite-reasons {
      & { display: flex; padding: 73px 0 30px; max-width: 1400px; margin: 0 auto; }
      .reason { 
        & { flex: 0 1 33%; font-size: 21px; padding: 0 10px; max-width: 30ch; margin: 0 auto; }
        span { color: #C86F3A; font-weight: 700; }
        img { height: 61px; }
      }
    }
    .cta-btn {
      & { background-color: #f6d650; display: inline-block; margin: 30px auto 0; color: #fff; padding: 0; border: 3px solid #ffffff; border-radius: 7px; }
      &:hover { background: #009f97; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: auto; display: inline-block; padding: 5px 25px; color: #343333; font-family: "lato", sans-serif; font-size: 21px; font-weight: 700; height: 50px; border-radius: 5px; }
      button:hover { background: #009f97; transition: 0.3s; color: #fff; }
    }
  }

  .team-container {
    & { max-width: calc(880px - 10%); border-radius: 10px; margin: 50px auto; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: #ffffff; text-align: center; font-weight: 400; }
    h3 { line-height: 42px; font-size: 36px; color: #ffffff; }
    .cta-btn {
      & { width: 220px; background: $color1; margin: 40px auto 0; color: #fff; padding: 5px 0; border-radius: 25px; }
      &:hover { background: $color2; transition: 0.3s; color: #fff; }
      a { display: block; text-decoration: none; color: #fff; }
      button { color: #fff; font-family: "lato", sans-serif; font-size: 18px; font-weight: bold; }
    }
  }

  .individual-group {
    & { max-width: 400px; width: 90%; margin: 70px auto 0; display: flex; }
    button { cursor: pointer; flex: 0 1 50%; border: 0px; background: #fff; color: #1F2A44; font-family: "lato", sans-serif; font-size: 18px; font-weight: 600; padding: 16px 0; text-align: center; }
    .left { border-top-left-radius: 100px; border-bottom-left-radius: 100px; font-weight: 700; color: $color2; }
    .right { border-top-right-radius: 100px; border-bottom-right-radius: 100px; font-weight: 700; color: $color2; }
    .active { background-color: $color2; color: #fff; font-weight: 700; }
  }

  .toggle-individual-group { display: none; }

  .dashboard {
    & { width: 96%; min-height: 630px; background-color: #fff; padding: 5px; box-sizing: border-box; }
    h1 { width: auto; text-align: center; margin: 0 auto; font-family: 'lato', serif; font-size: 36px; color: $color3; padding: 32px 32px 0; max-width: 45ch; }
    
    .progress-bar { & { margin: 90px auto 90px; position: relative; max-width: 90%; }
      .pervalue h4 { position: relative; top: 102px; }
      .pervalue h4.nimp1:after { content: "0%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.nimp:after { content: "20%"; position: absolute; right: -19px; bottom: 35px; }
      .pervalue h4.mexpe:after { content: "50%"; position: absolute; right: -19px; bottom: 35px; }
      // .pervalue h4.eexpe:after { content: "60%"; position: absolute; right: -19px; bottom: 35px; }
      .pervalue h4.eexpe1:after { content: "80%"; position: absolute; right: -19px; bottom: 35px; }
      .pervalue h4.eexpe2:after { content: "100%"; position: absolute; right: -8px; bottom: 35px; }
      .pervalue-text { & { position: relative; display: block; margin: 0 auto; width: 100%; max-width: 680px; bottom: -75px; }
        p { text-align: center; display: inline-block; text-align: center; margin: 0 auto; font-size: 16px; vertical-align: top; color: #575757; }
      } 
    }
    .single-group_assessment #group-sections .si_ass_result { padding: 7% 0px 3%; }
    span.msi_name { color: #d3202b; font-size: 18px; font-weight: 900; font-family: "Avenir", Sans-serif; }
    .pro_top_details span { display: block; line-height: 30px; min-width: 180px; }
    .pro_top_details{ position: absolute; bottom: 45px; left: 0; max-width: 130px; width:100%; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .in-ass-result .si_ass_progress span, .single-assesment .si_ass_progress span { position: absolute; font-size: 30px; bottom: 42px; left: 0; font-weight: bold; color: #4A4A4A; font-family: "Aleo", Sans-serif; }
    .si_ass_progress:before {  }
    .single-group_assessment #group-sections .si_ass_progress:before { height: 45px; }

    .si_ass_progress { position: relative; max-width: 680px; height: 26px; margin: 0 auto; border-radius: 12px; width: 100%; 
      background-image: linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%);
      // background-image: linear-gradient(to right, #cc2c24 69.5%, #E98526 70% 79.5%, #FFCA27 80% 94.5%, #4E9A51 95% 100%);
    }
    .si_ass_progress .progress-line { width: 3px; z-index: 1; height: 55px; content: ' '; background: $color3; left: 0; bottom: 0; position: absolute; -webkit-transition: all .5s ease-in-out; -moz-transition: all .5s ease-in-out; -o-transition: all .5s ease-in-out; transition: all .5s ease-in-out; }
    .si_ass_progress .progress-line .progress-text { position: absolute; top: -65px; left: -100px; width: 200px; font-size: 18px; color: $color3; height: 30px; text-align: center; }
    .si_ass_progress .msi_score { display: block; position: absolute; font-size: 30px; font-weight: bold; color:  $color3; bottom: 50px; left: 0; margin-left: -20px; }
    
    
    // .si_ass_progress .msi_score::after { display: block; margin: 0 33% 1rem 33%; content: ""; height: 50px; background-color: black; width: 2px; color: black; }
    
    
    
    .pervalue h4 { position: relative; top: 50px; }
    .pervalue { max-width: 680px; margin: -68px auto; display: flex; clear: both; color: $color3; font-size: 16px; }
    .pervalue h4.nimp:after { content: '70%'; position: absolute; right: -19px; }
    .pervalue h4.mexpe:after { content: '80%'; position: absolute; right: -19px; }
    .pervalue h4.eexpe:after { content: '95%'; position: absolute; right: -19px; }

    .si_pro_header { max-width: 680px; margin: 10px auto 0; display: flex; clear: both; }
    .si_pro_header h4 { position: relative; right: -3px; font-size: 16px;  font-weight: 400; text-align: center; color:  $color3; text-transform: capitalize; margin-top: 10px; }
    .si_pro_header h4.nimp:before { width: 3px; height: 28px; content: ' '; background: #fff; position: absolute; right: 0; bottom: -75px; }
    .si_pro_header h4.mexpe:before { width: 3px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe:before { width: 3px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4.eexpe1:before { width: 3px; height: 28px; content: ' '; background: #fff; bottom: -75px; position: absolute; right: 0; }
    .si_pro_header h4 span { color: #D3202B; line-height: 35px; font-size: 16px; }
    
    .result-text { & { width: 95%; max-width: 950px; box-sizing: border-box; text-align: left; font-size: 18px; padding: 15px 30px 15px; margin: 20px auto; background-color: #EEEEEE; border-radius: 10px; }
      p { max-width: 95%; text-align: left; margin: 1rem auto; }
  }
    .result-sections { 
      & { margin: 50px 5%; margin-bottom: 0; display: flex; justify-content: center; flex-wrap: wrap; }
      .score-name { text-transform: uppercase; }
      .score-section { 
        & { flex: 0 1 20%; text-align: center; font-weight: 700; font-size: 18px; margin: 10px auto; }
        .score-txt { font-size: 18px; font-weight: 700; margin-bottom: 28px; }
        .score-slider { width: 80%; margin: 0 auto; margin-bottom: 16px; }
      }
    }

    .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; height: 10px; border-radius: 5px; max-width: 200px; margin: 0 auto; }
    .custom-bar .MuiLinearProgress-bar { background-color: $color1; }

    table { 
      & { font-family: 'lato', sans-serif; border-collapse: collapse; width: 86%; margin: 70px auto 40px; font-size: 18px; font-weight: 400; color: $color3; }
      tr td:first-child { font-weight: 700; }
      tr:nth-child(even) { background-color: #F4F4F4; }
      td, th { border: 1px #dddddd;text-align: left;padding: 8px; }
      .center-cell { text-align: center; }
      .medium { font-weight: 500; }
      .hash { width: 13px; }
      .avg { text-align: center; }
      .header { border-style: none;border-bottom: 3px solid #007670; }
      .footer { 
        & { border-style: none;border-top: 3px solid #007670; color: $color1; }
        .church-average { font-size: 15px; color: $color3; }
      }
    }

    hr { width: 96%; color: #979797; }
 
    h2 { font-family: "lato", serif; font-size: 24px; font-weight: 700; color: #27282A; margin-top: 50px; text-align: center; }
    .most-answered {
      & { width: 96%; max-width: calc(880px - 10%); background: #FBFBFB; margin: 20px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; color: #1f2a44; text-align: left; border-radius: 10px; overflow: hidden; }
      .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 10px;  border-top-right-radius: 10px; }
      .most-answered-detail { font-weight: 400; font-size: 18px; color: $color3; padding: 10px 30px; width: calc(100% - 60px);}
      .most-answered-detail:nth-child(even) { background-color: #f4f4f4; }
    }

    .alignment { 
      & { width: 95%; max-width: calc(860px - 10%); margin: 0 auto 30px; background-color: $color1; margin: 50px auto 0; padding: 0px; border-radius: 10px; }
      .container-tabs { display: flex; flex-direction: column; position: relative; width: 100%; min-height: 150px; }
      .tab-buttons { 
        & { display: flex; flex-wrap: wrap; width: 100%; margin: 0 auto; justify-content: center; background-color: #EEEEEE; padding: 10px 20px 0px; box-sizing: border-box; border-radius: 10px 10px 0 0  ; }
        button { font-family: "lato", sans-serif; color: #585252; font-size: 18px; border-radius: 8px 8px 3px 3px; font-weight: 600; text-transform: uppercase; text-align: center; padding: 15px; background: none; border: 0px; min-width: 110px; cursor: pointer; }
        .active { background-color: #007670; color: #FFFFFF; }
      }
      .tabs-content { flex-grow: 1; padding: 10px; }

      .content { 
        & { width: 100%; display: none; }
        .align-details {
          & { max-width: calc(880px - 10%); background: #FBFBFB; margin: 10px auto; padding: 0px 0% 0px; font-family: "lato", sans-serif; font-size: 18px; text-align: left; border-radius: 10px; overflow: hidden; }
          .green { background-color: #73B76F; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-radius: 10px 10px 0 0; }
          .red { background-color: #FF6645; color: #fff; padding: 10px 17px; margin: 0; font-weight: 700; border-top-left-radius: 3px;  border-top-right-radius: 3px; }
          .answered-detail { 
            & { display: flex; flex-wrap: wrap; align-items: center; font-weight: 400; font-size: 18px; color: $color3; padding: 10px 15px; width: calc(100% - 30px); }
            // .answer { flex: 1 1 200px; margin-left: 10px; text-align: right; font-weight: 700; }
            .answer { flex: 1 1 200px; font-weight: 400; text-transform: uppercase; text-align: right; font-size: 14px; }

            .true { color: #73B76F; }
            .neutral { color: #FFB545; }
            .false { color: #FF6645; }
            p { flex: 0 1 94%; }
            .more-toggle { display: block; flex: 0 1 6%; cursor: pointer; color: #126496; }
            .more-details { 
              & { display: none; width: 91%; margin-left: 5%; padding: 5px 2%; border-left: 3px solid #126496; font-size: 14px; }
              .more-row { 
                & { display: flex; align-items: center; }
                p { max-width: 25%; padding: 5px; margin: 0; }
                .more-answer { text-transform: uppercase; font-weight: bold; }
              }
            }
          }
          .no-wrap { flex-wrap: nowrap; }
          .answered-detail:nth-child(even) { background-color: #f4f4f4; }
        }
        
      }
      .active-content { display: block; }
    }

    .mobile_scores {
      & { display: none; width: 90%; margin: 0 auto; }
      .individual {
        h2 { font-size: 18px; font-weight: 700; }
        & { font-size: 21px; font-weight: 700;border-bottom: 2px solid #007670;  }
        .row { 
          & { height: 40px; display: flex; align-items: center; border-top: 2px solid #007670; }
          &:last-of-type { border-bottom: 2px solid $color3; }
          .number { flex: 0 1 30px; font-size: 18px; }
          .name { flex: 1 1 auto; }
          .percent { font-size: 18px; margin-right: 10px; }
          .toggle { padding-top: 6px; color: #73B76F; }
        }
        .scores { 
          & { font-size: 18px; border-top: 2px solid #007670; }
          .section_rows { 
            & { display: flex; padding: 12px 16px; }
            &:nth-of-type(even) { background-color: #F4F4F4; }
            .section { flex: 1 1 auto; }
            .section_score { flex: 0 1 auto; }
          }
        }
      }
    }

    .urgency {
      & { margin: 0 auto; padding: 1rem 0; max-width: calc(880px - 10%); }
      h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: center; }
      .urgency-slider { 
        & { position: relative; background: #FAF2E980; margin: 0 auto; padding: 115px 9% 80px; border-radius: 3px; }
        h3 { position: absolute; top: 0; color: #152036; font-weight: 600; font-size: 15px; left: 7%; }

        .slider-labels {
          & { width: 100%; font-weight: 400; font-size: 13px; margin: 0 auto; }
          .label {
            & { width: 20%; float: left; position: relative; }
            p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; text-align: center; z-index: 1000; }
          }
        }
      
      }

      .slider-points {
        & { margin-top: -20px; width: 100%; font-weight: 300; font-size: 14px; color: #343333; position: relative; }
        .score { position: absolute; z-index: 100000; margin-left: -13px; margin-top: -12px; text-align: center; font-size: 18px; font-weight: bold; color: #fff; }
        .top-labels { position: absolute; width: 1px; height: 33px; top: -46px; margin-left: -2px; background: #1F2A44; }
        .top-points { position: absolute; top: -70px; margin-left: -6px; font-weight: bold; font-size: 18px; color: #152036; }
        .point {
          & { cursor: pointer; width: 13px; height: 13px; border-radius: 8px; margin-left: -8px; margin-top: -6px; background: $color1; position: absolute; z-index: 9; }
        }
      }

      .custom-slider.MuiSlider-root { & { z-index: 1000; color: $color1opacity42; width: 100%; margin-left: 0px; }
        span.MuiSlider-track { left: -8px !important; }
     }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 8px solid $color1opacity60; height: 0; }

      .custom-slider .MuiSlider-thumb { background: $color1; }
      .custom-slider .MuiSlider-thumb:before { background: $color1; border: 4px solid $color1; width: 36px; height: 36px; box-shadow: 0px 0px 0px 0px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px rgba(247, 213, 77, 0.5); }
      
      .MuiLinearProgress-root.custom-bar { background-color: #B8B8B8; height: 2px; }
      .custom-bar .MuiLinearProgress-bar { background-color: $color1; }
    }

  }

  .dashboard .hurdles-enablers, .results .hurdles-enablers {
    & { max-width: 800px; width: 95%; margin: 20px auto; }
    h2 { font-family: "lato", sans-serif; font-weight: 700; text-transform: uppercase; font-size: 18px; text-align: left; color: #202945; }
    .hurdles { background: #fbece9;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
    .enablers { background: #f1f8f1;  padding: 10px 4% 1px 2%; font-family: "lato", sans-serif; font-size: 16px; line-height: 1.5em; color: $color3; text-align: left; border-radius: 13px; }
    li { padding-bottom: 16px; font-weight: 400; font-size: 18px; }
  }

  // .testimonal-container {
  //   & { max-width: calc(880px - 4%); margin: 50px auto; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: #1F2A44; display: flex; }
  //   .avatar { margin-right: 53px; }
  //   .testimonial { 
  //     & { position: relative; color: #fff; font-family: 'lato', sans-serif; font-size: 21px; line-height: 1.35em; }
  //     .quotation-mark { position: absolute; margin-top: -50px; }
  //     .designation { 
  //       & { color: $color2; font-weight: 700; padding-top: 20px; }
  //       span {  font-weight: 400; }
  //     }
  //   }
  // }
  .testimonal-container {
    & { max-width: calc(880px - 4%); margin: 50px auto 0; padding: 75px 2% 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; }
    .testimonial { 
      & { position: relative; color: #ffffff; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
      .avatar {
        img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
      }
      .quotation-mark { 
        & { display: inline-block; font-size: 84px; color: $color2; margin-right: 15px; position: relative; }
        .quotation-block { position: absolute; right: 15px; top: -15px; }
      }
      .bottom { transform: scale(-1, -1); margin-left: 15px; }
      .designation { 
        & { color: $color2; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
        span { font-weight: 600; font-size: 18px; color: #ffffff; }
      }
    }
  }

  .schedule-container {
    & { width: 96%; box-sizing: border-box; max-width: calc(880px - 10%); background: #fff; margin: 20px auto; padding: 45px 20px 45px; font-family: "lato", sans-serif; font-size: 21px; line-height: 32px; color: #122845; text-align: center; border-radius: 10px; }
    h3 { font-size: 36px; line-height: 10px; }
    .cta-btn {
      & { width: auto; display: inline-block; background: #2D8D95; margin: 40px auto 0; color: #fff; padding: 0; border-radius: 25px; }
      &:hover { background: $color2; color: $color3; transition: 0.3s; }
      a { display: block; text-decoration: none; color: #fff; }
      button { width: 220px; color: #fff; padding: 10px 0; font-family: "lato", sans-serif; font-size: 18px; border-radius: 3px; font-weight: bold; }
    }
  }
  .invite-legacy-container {
    & {display: flex; justify-content: center; align-items: center;}

    .invite-legacy {
      & { background-color: #FFF; margin: 50px 25px; padding: 60px 2% 30px; font-family: "lato", sans-serif; font-size: 24px; line-height: 36px; color: $color3; text-align: center; border-radius: 10px; width: 50ch; }
      p { margin: 0 auto; max-width: 45ch; }
      .cta-btn {
        & { width: 280px; background: $color1; margin: 1.5rem auto 1rem auto; color: #fff; padding: 5px 0; border-radius: 35px; }
        &:hover { background: $color2; color: $color3; transition: 0.3s; color: #fff; }
        a { display: block; text-decoration: none; color: #fff; }
        button { color: #fff; font-family: "lato", sans-serif; font-size: 21px; border-radius: 3px; font-weight: bold; }
      }
    }
  }

}

.testimonial-container {
  & { max-width: 880px; width: 90%; box-sizing: border-box; margin: 45px auto 0; padding: 45px 30px 30px; font-family: 'lato', sans-serif; font-size: 24px; line-height: 36px; color: $color3; display: flex; justify-content: center; }
  .testimonial { 
    & { position: relative; color: #ffffff; font-family: 'lato', sans-serif; font-size: 24px; line-height: 45px; text-align: center; }
    .testi { max-width: 55ch; }
    .avatar {
      img { border-radius: 50%; width: 120px; margin-top: 50px; margin-bottom: -20px; }
    }
    .quotation-mark { 
      & { display: block; text-align: left; font-size: 84px; color: #FFF; margin-right: 15px; position: relative; line-height: 0; left: -20px; } // top: 13.5px;
      // .quotation-block { position: absolute; right: 15px; top: -15px; }
    }
    .quotation-mark.bottom { transform: scale(-1, -1); margin-left: 15px; text-align: left; top: -10px; left: -40px; top: -15px; left: 40px; }
    .testimonial-text { padding: 2px 0; max-width: 45ch;}
    .designation { 
      & { color: #ffffff; font-weight: 700; padding-top: 32px; line-height: 1.1em; font-size: 32px; }
      span { font-weight: 600; font-size: 18px; color: #ffffff; }
    }
  }
}

.btn {
  & {
    
  }
}

footer {
  min-height: 100px;
}

footer { min-height: 100px; }

@media only screen and (min-width: 650px) {

  main .assessment-additional .steps .slider-container .slider-labels br { content: ""; }
  main .assessment-additional .steps .slider-container .slider-labels br:after { content: " "; }

}

@media only screen and (max-width: 1250px) {

  header {
    .logo { margin-left: 0; }
    .bleat-logo { right: 50px; }
  }
  
  .custom-slider.MuiSlider-root { padding: 12px 0; }  

}

@media only screen and (max-width: 1110px) {

  main .dashboard .urgency { overflow: hidden; }
  main .testimonal-container .testimonial .testi br { content: ""; }
  main .testimonal-container .testimonial .testi br:after { content: " "; }

}

@media only screen and (min-width: 831px) {
  // main .bleat-logo-mobile { display: none; }
  main .container.start { margin: 80px auto; }
}

@media only screen and (max-width: 830px) {

  header {
    & { height: auto; // flex-direction: column; align-items: flex-start; 
    }
    .logo { position: relative; margin: 0 auto; left: 0; margin: 20px auto; padding: 0 0 0 10px; width: auto; }
    .assessment-title { margin: 10px 40px 10px auto; 
      .logo-text { font-size: 36px; line-height: 42px; }
      .logo-subtext { font-size: 20px; line-height: 25px; letter-spacing: 8px; }
    }
    .bleat-logo { display: none; }
  }

  footer { & { min-height: 0; height: 0; }
    .bleat-logo-mobile { 
      & { display: flex; justify-content: center; padding: 15px 0 30px; text-align: center; width: 100%; }
      img { height: 43px; width: 105px; }
    }
  }


  
  main {
    .container.assessment { margin-bottom: 20px; margin: 30px auto; }
    .invite-legacy-container .invite-legacy { margin-bottom: 20px; }
    .container.start { margin: 15px auto; }
    .start {
      & { flex-direction: column; height: auto; width: 95%; }
      .video { width: 100%; height: auto; border-radius: 10px 10px 0 0; }
      .intro { 
        & {width: 90%; padding: 30px 5%; height: auto; align-items: center; }
        h1 { margin: 0; line-height: 35px; text-align: center; width: auto; font-size: 2rem; margin-bottom: 15px; }
        h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
        .completion { 
          & { flex-direction: column; margin: 30px auto 0 auto; width: 100%; }
          .time-estimate { margin: 15px auto; }
          // .cta { margin-bottom: 0; max-width: 280px; }
          .cta { margin-bottom: 0; max-width: 95%; }

        }
      }
    }
  }
  main .assessment .steps .slider-container .slider {
    .custom-slider.MuiSlider-root { color: #2D8D95; }
    .custom-slider .MuiSlider-thumb { background-color: #2D8D95; border: 3px solid #2D8D95; }
    .custom-slider .MuiSlider-track { border: 5px solid #2D8D95; }
    .custom-slider .MuiSlider-thumb:before { background: #2D8D95; }
  }
  main .assessment .steps .slider-container .slider-labels .active { color: #2D8D95 }
  main .start .video video { width: 160%; margin: 0 -10%; object-fit: cover; max-width: 45vw; max-width: 25vh; /* new styles for portrait mode thumbnail & video = width: auto; height: 100%; max-height: 45vw; */ }
  main .start .video .thumbnail { width: 100%; height: auto; /* new styles for portrair mode video and thumbnail = max-height: 40vw; max-width: 30vw; */ }
  main .assessment .steps {
    .progress-percent-text { display: none; }
    .progress-bar { 
      & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
      .bar-section { width: 90% !important; }
    }
  }
  main .details .form { width: 80%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; } 
  main .dashboard table { font-size: 15px; }
  main .dashboard .most-answered { width: 90%; }
  main .dashboard .urgency { margin: 1rem auto; max-width: 90%; }
  main .dashboard .hurdles-enablers { width: 90%; }  

}

@media only screen and (min-width: 650px) {

  // main .dashboard .urgency .urgency-slider .slider-labels .label p br { content: ""; }
  // main .dashboard .urgency .urgency-slider .slider-labels .label p br:after { content: " "; }

}


@media only screen and (max-width: 650px){
  main .results .pervalue h4.eexpe2:after { right: -8px; }
  main .results .pervalue h4.nimp1:after { right: -24px; }
}





@media only screen and (max-width: 650px) {


  header { .header-wrapper { display: grid; grid-template-columns: 60px 1fr 60px; height: auto; }
    .logo img { height: 47px; width: auto; max-width: 60px; }
    .assessment-title { margin: 10px auto; img { height: auto; }
      .logo-text { font-size: 24px; line-height: 25px }
      .logo-subtext { font-size: 18px; line-height: 25px; }}
  }
  main .assessment .steps { width: 90%; padding: 0 5%; }
  main .container.assessment { margin: 30px auto; }
  main .start .video {
    // .playbtn { width: 144px; height: 144px; }
    .caption { & { padding: 4px 0 4px; }
      p { margin: 1px auto; font-size: 14px; line-height: 17px; }
      p.name { font-weight: 700; font-size: 18px; line-height: 22px; }
    }
  }
  // main .start .video .playbtn { width: 144px; height: 144px; }
  main .start .intro h1 { text-align: center; }
  main .start .intro h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
  main .start .intro h1 br { content: ""; }
  main .start .intro h1 br:after { content: " "; }
  main .assessment .mobile-spacer { display: none; }
  main .assessment .steps .progress-bar .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
  main .results {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { width: 90%; position: relative; margin: 70px auto 80px; }
    .result-sections .score-section { font-weight: 400; }
    // .pervalue h4.nimp1:after { content: '0%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.nimp:after { content: '45%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.mexpe:after { content: '70%'; position: absolute; right: -19px; bottom: -15px; }
    // .pervalue h4.eexpe:after { content: '100%'; position: absolute; right: -19px; bottom: -15px; }

    .pervalue-text { & { margin: 10px auto; position: relative; width: 100%; bottom: -65px; }
      p { display: inline-block; text-align: center; margin: 0 auto; font-size: 13px; color: #4A4A4A; }
    }
    .score-section-container {
      & { flex-direction: column; padding: 0 0 20px 0; max-width: 90%; margin-top: 0; }
      .score-section { 
        // & { margin: 10px auto; min-width: 200px; width: 75%; max-width: 350px; }
        & { margin: 10px auto; flex: 0 1 50%; max-width: 80%; width: 80%; }
        .score-txt { font-size: 18px; font-weight: 700; margin: 0.25rem auto; }
        .score-name { font-size: 18px; font-weight: 400; margin: 0.25rem auto; text-transform: uppercase; }
        .score-slider { width: 90%; margin: 0 auto; margin-bottom: 16px; max-width: 200px; }
        
      }
    }
    .spacer{ height: 10px; }
    .selection-form { & { font-size: 24px; }
      .form-details-text .MuiInput-root { font-size: 28px; }
    }

    .si_ass_progress {width: 95%;}
  }
  main .dashboard {
    h1 { font-size: 30px; padding: 24px 16px 16px; }
    .progress-bar { & { width: 90%; position: relative; margin: 90px auto 90px; } 
      .pervalue-text { & { margin: 10px auto; bottom: -65px; }
        p { font-size: 13px; } }
    }
    .result-sections .score-section { flex: 0 1 95%; }
    .result-sections .score-section .score-txt { margin: 0.25rem auto; }
    .alignment .tab-buttons { padding: 10px 15px; }
    .alignment .tab-buttons button { border-radius: 10px; }
  }
  .testimonial-container { width: 95%; }
  .testimonial-container .testimonial .designation { font-size: 24px; }
  main .invite-legacy-container .invite-legacy { & { padding: 3rem 1rem; }
    .cta-btn { margin: 2rem auto 0 auto; }
  }

  // .testimonial-container .testimonial .testimonial-text { max-width: 30ch; font-size: 18px; }
  .testimonial-container .testimonial .quotation-mark { font-size: 54px; }
  main .invite-container-new h3 { text-align: left; font-size: 21px; line-height: 28px; margin: 5px auto; }
  main .invite-container-new p { text-align: left; font-size: 18px; line-height: 32px; }
  .testimonial-container .testimonial .testimonial-text { font-size: 19px; line-height: 30px; font-weight: 400; }

  main .results .si_ass_progress .progress-line { width: 3px; height: 35px; }
  main .results .si_ass_progress .msi_score{ bottom: 33px; }
  main .results .result-text { padding: 15px 30px 15px; }
  main .container.results { margin: 30px auto 30px; }
  .testimonial-container .testimonial .quotation-mark { left: 0px; top: 5px; }
  .testimonial-container .testimonial .quotation-mark.bottom { left: 20px; }
  main .invite-container-new { margin: 30px auto; }
  main .invite-container-new .cta-btn { margin: 10px auto 0; }
  main .assessment-additional .steps { width: 90%; }
  main .assessment-additional .steps .progress-indicator { margin: 45px auto 30px; }
}

@media only screen and (max-width: 768px) {
  
  main .invite { width: 95%; margin-top: 15px; }
  main .invite h1 { font-size: 32px; width: 95%; }
  main .invite h1 br { content: ""; }
  main .invite h1 br:after { content: " "; }
  main .invite p { margin: 0 auto; font-size: 18px; }
  main .invite .invite-text{margin: 2% auto; max-width: 80%;}
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 65%; }
  main .invite .form .form-detail { flex: 0 1 100%;padding-top: 1rem; padding-bottom: 1rem;}
  main .invite .form .form-detail .invite-field { max-width: 95%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 14px; }

  main .invite .form .delete-btn { right: auto; left: -32px; top: 55px; }
  main .invite-legacy-container .invite-legacy { font-size: 18px; }
  main .invite .invite-another-person .invite-another { top: 30px; right: -26px; left: 0; margin-left: -32px; }
  main .invite .cta-btn { margin-top: 20px; margin-right: 15%; width: 164px; min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 20px; }
  main .invite .details-container .form-container .hr-line {border: 1px solid #e4e4e4; width: 95%; left: 50%; position: relative; transform: translate(-50%, 50%);}
  main .details .form .form-detail { flex: 0 1 100%; max-width: 100%; }
  main .details .details-buttons .back-btn{position: relative; top: 2.75rem;}

  main .thank-you h1{font-size: 28px; max-width: 70%;}
} 


@media only screen and (max-width: 550px) {
  
  main .start { margin-top: 15px; }
  main .start .video { min-height: 45vw; height: auto; border-top-left-radius: 10px; border-top-right-radius: 10px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; }
  main .start .intro { 
    h1 { font-size: 21px; margin-bottom: 15px; line-height: 25px; }
    h2 { font-size: 17px; max-width: 34ch; }
    .intro-points-list { font-size: 18px; font-weight: 400; line-height: 27px; }
    .completion img { margin: 0 4px 0 0; }
  }
  
  main .assessment { margin-top: 15px; }
  main .assessment .steps .question {min-height: 180px; font-size: 21px; max-width: 100%; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .slider-container .slider-labels .label { width: 14%; }
  main .assessment .steps .progress-indicator { margin: 50px auto 30px; }
  main .assessment .steps .progress-bar { grid-gap: 0; }
  main .assessment .steps .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 12px; }
  main .assessment .steps .progress-indicator { font-size: 18px; }
  main .assessment .steps .slider-container .slider-labels { font-size: 14px; }
  main .assessment .steps .slider-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .slider-container .slider-labels .label p.mobile { display: block; }
  main .assessment .steps .radio-container .slider-labels { font-size: 12px; }
  main .assessment .steps .radio-container .slider-labels .label p.desktop { display: none; }
  main .assessment .steps .radio-container .slider-labels .label p.mobile { display: block; }
  main .assessment .steps .step-buttons { margin: 120px auto 30px; padding-bottom: 20px; }
  
  main .assessment-additional { margin-top: 15px; }
  main .assessment-additional .steps .textarea-field { width: 100%; margin-left: -10px; }
  main .assessment-additional .steps .form-details-text { height: 200px;  }
  main .assessment-additional .steps .question {font-size: 21px; min-height: auto; margin: 0 auto 30px; }
  main .assessment-additional .steps .question p br { content: ""; }
  main .assessment-additional .steps .question p br:after { content: " "; }
  main .assessment-additional .steps .slider-container .slider-labels { font-size: 12px; }
  main .assessment-additional .steps .step-buttons { padding-bottom: 30px; }
  // main .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  main .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; }

  main .details { width: 95%; margin-top: 15px; }
  main .details h1 { font-size: 32px; }
  main .details p { padding: 0 5%; font-size: 18px; }
  main .details p br { content: ""; }
  main .details p br:after { content: " "; }
  main .details .form { width: 90%; }
  main .details .form .form-detail { flex: 0 1 100%; max-width: 100%; }
  main .details .form .form-detail .form-details-text { padding-right: 0; max-width: 100%;
  }
  main .details .details-buttons { & { max-width: 90%; display: flex; flex-direction: column-reverse; }
    .back-btn { align-self: flex-start; margin-top: 2rem; }
    .cta-btn { & { width: auto; }
      button { width: auto; padding: 8px 1.5rem; } }
  } 
  // main .details .form .form-detail .form-details-text.react-select { max-width: 97.5%; }

  main .results { width: 95%; margin-top: 15px; }
  main .results .selection-form { width: max-content; max-width: 95%; flex-wrap: wrap; }
  main .results .selection-form .form-detail { width: 100%; margin-right: 0; }
  main .results .selection-form .form-text { width: 100%; margin-bottom: 10px; text-align: center; font-size: 18px; margin-top: 8px; }
  main .results h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .results .result-text { margin: 20px auto; font-size: 18px; }
  main .results .result-sections { margin: 50px auto; padding-bottom: 50px; }
  main .results .result-sections .score-section { flex: 0 1 100%; margin-bottom: 20px; }
  main .invite-container .cta-btn button { font-size: 16px; padding: 5px 15px; line-height: 16px; }
  main .results .result-sections .score-section .score-txt { margin-bottom: 9px; }
  main .results .progress-bar { & { width: 90%; }
    .pervalue h4::after { font-size: 13px; }}
  main .results .si_pro_header h4 { font-size: 13px; }
  // main .results .si_pro_header h4:before { bottom: -83px !important; }
  // main .results .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .results .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .results .si_ass_progress .msi_score { font-size: 26px; }
  main .results .members-answered { width: 95%; font-size: 16px; }
  main .results .members-answered .answered-detail { font-size: 14px; padding: 10px 15px; width: calc(100% - 30px); }

  main .invite-container { width: 85%; font-size: 18px; }
  main .invite-container p br { content: ""; }
  main .invite-container p br:after { content: " "; }
  main .invite-container h3 { font-size: 24px; line-height: 38px; }
  main .invite-container .invite-reasons { flex-wrap: wrap; padding: 20px 0 20px; }
  main .invite-container .invite-reasons .reason { flex: 0 1 100%; font-size: 18px; margin-bottom: 50px; margin-top: 20px; }

  main .team-container { width: 90%; font-size: 18px; }
  main .team-container h3 { font-size: 24px; line-height: 38px; }

  main .schedule-container { width: 85%; font-size: 18px; }
  main .schedule-container h3 { font-size: 24px; }
  // main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  // main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }

  main .testimonal-container { flex-wrap: wrap; padding-top: 12px; }
  main .testimonal-container .avatar { flex: 0 1 100%; text-align: center; margin-right: 0; }
  main .testimonal-container .testimonial .quotation-mark { font-size: 46px; }
  main .testimonal-container .testimonial .designation { font-size: 26px; }
  main .testimonal-container .testimonial .designation span { padding-top: 6px; }

  main .assessment-additional .steps .textarea { min-height: 120px; }
  main .assessment-additional .steps .textarea-field { margin-bottom: -77px; }
  main .invite { width: 95%; margin-top: 15px; }
  main .invite h1 { font-size: 24px; max-width: 20ch; padding-top: 30px; }
  main .invite h1 br { content: ""; }
  main .invite h1 br:after { content: " "; }
  main .invite p { margin: 5px 2% 10px; font-size: 18px; max-width: 75% !important;}
  main .invite p br { content: ""; }
  main .invite p br:after { content: " "; }
  main .invite .form { width: 65%; }
  main .invite .form .form-detail { flex: 0 1 100%; }
  main .invite .form .form-detail .invite-field { max-width: 95%; }
  main .invite .form .form-detail .error-text { bottom: auto; }
  main .invite .form .invite-number { left: -25px; top: 25px; }
  main .invite .form .delete-btn { right: auto; left: -32px; top: 64px; }
  main .invite-legacy-container .invite-legacy { font-size: 18px; }

  main .invite .invite-another-person .invite-another { top: 30px; right: -26px; left: 0; margin-left: -32px; }
  main .invite .cta-btn { margin-top: 20px; margin-right: 15%; width: 164px; min-width: 160px; padding: 4px 0; width: auto; padding: 4px 15px; font-size: 20px; }
  main .thank-you { width: 95%; }
  main .thank-you h1 { font-size: 24px; max-width: 22ch; }
  main .thank-you p { width: 94%; margin: 25px 3% 35px; font-size: 18px; }
  main .testimonal-container .testimonial { font-size: 18px; width: 90%; margin: 20px auto 0; text-align: center; }

  
  main .MuiSwitch-thumb { background-color: #73B76F !important; position: relative; }
  main .MuiSwitch-thumb::after { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); content: ""; width: 3px; height: 3px; border: 3px solid #FFFFFF; border-radius: 50%; padding: 2px; background-color: transparent !important; }
  main .MuiSwitch-track { background-color: #D8D8D8 !important; opacity: 1 !important; }
  main .individual-group { display: none; }
  main .toggle-individual-group { display: block; margin: 15px auto; display: flex; align-items: center; justify-content: center; color: #fff; font-size: 21px; }
  main .toggle-individual-group .left { flex: 0 1 25%; text-align: right; color: #ffffff; }
  main .toggle-individual-group .right { flex: 0 1 35%; color: #ffffff; }
  main .toggle-individual-group .active { font-weight: 700; color: #ffffff; }
  main .dashboard { width: 95%; margin-top: 15px; }
  main .dashboard h1 { font-size: 24px; padding: 1.5rem 1rem 1rem; width: auto; }
  main .dashboard .mobile_scores { display: block; }
  main .dashboard .result-text { margin: 20px auto; font-size: 18px; }
  main .dashboard .result-sections { margin: 50px 5%; padding-bottom: 0; }
  main .dashboard .result-sections .score-section .score-txt { margin-bottom: 10px; }
  main .dashboard .result-sections .score-section .score-name { font-weight: 400; }
  main .dashboard .progress-bar { width: 90%; }
  main .dashboard .si_pro_header h4 { font-size: 13px; }
  // main .dashboard .si_pro_header h4.nimp:before { bottom: 63px; }
  // main .dashboard .si_pro_header h4.mexpe:before { bottom: 63px; }
  main .dashboard .progress-bar .pervalue h4::after { font-size: 13px; }
  main .dashboard .si_ass_progress .msi_score { font-size: 26px; }
  main .dashboard hr { display: none; }
  main .dashboard table { font-size: 13px; display: none; }
  main .dashboard table .footer .church-average { font-size: 13px; }
  main .dashboard table .header .center-cell { font-size: 0; }
  main .dashboard .urgency h2 { text-align: center; }
  main .dashboard .urgency .urgency-slider h3 { text-align: center; left: 0; width: 100%; }
  main .dashboard .urgency .slider-points .top-points { font-size: 13px; }
  main .dashboard .urgency .urgency-slider .slider-labels { font-size: 12px; }
  main .dashboard .most-answered { font-size: 16px; width: 95%; }
  main .dashboard .most-answered .most-answered-detail { font-size: 14px; }
  main .dashboard .alignment .content .align-details .answered-detail { font-size: 14px; }
  main .dashboard .alignment { width: 95%; }
  main .dashboard .hurdles-enablers, main .results .hurdles-enablers { width: 95%; }
  main .dashboard h2 { font-size: 18px; font-weight: 700; }
  main .dashboard .alignment .tab-buttons { flex-wrap: wrap; }
  main .dashboard .alignment .tab-buttons button { font-size: 15px; min-width: 55px; padding: 10px 5px; flex: 0 1 32%; }
  main .dashboard .hurdles-enablers ul { & { padding-left: 30px; } 
    li { font-size: 14px; } }
  main .dashboard .alignment .content .align-details { font-size: 16px; }
  main .dashboard .alignment .content .align-details .answered-detail p { flex: 0 1 90%; }
  main .dashboard .alignment .content .align-details .answered-detail .more-toggle { flex: 0 1 8%; }
  main .dashboard .urgency .urgency-slider { margin: 0; background: transparent; }
  main .dashboard .urgency .custom-slider.MuiSlider-root { color: transparent; }
  main .dashboard .urgency .custom-slider .MuiSlider-track { border: 0px; }
  main .dashboard .urgency .MuiLinearProgress-root.custom-bar { background-color: #D3202B80; height: 8px; margin-top: -22px; border-radius: 5px; width: calc(100% + 3px); margin-left: -2px; }
  main .dashboard .urgency .slider-points .point { width: 8px; height: 8px; margin-top: -2px; margin-left: -5px; }

  main .results .selection-form .form-details-text .MuiInput-root { font-size: 24px; }
  main .dashboard .alignment .content .align-details.analyze .answered-detail { flex-direction: row-reverse; }

}

@media only screen and (max-width: 550px) {

  main .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }  
  main .start .intro .completion { & { margin: 10px auto 0; width: 100%; justify-content: center;  }
    .time-estimate { margin-bottom: 0; }
  }
  main .start .intro .completion .cta { margin: auto 15% 0; }

}

@media only screen and (max-width: 450px) {

  // main .dashboard table { font-size: 11px; }
  // main .dashboard table .footer .church-average { font-size: 11px; }

}

@media only screen and (min-width: 551px)
{
  main .assessment-additional .steps .question {padding-bottom: 1rem;} 
}

@media only screen and (min-width: 625px)
{
  main .assessment-additional .steps .question {padding-bottom: 0rem;} 
}


@media only screen and (min-width: 831px) and (max-width:1110px)
{
  main .start .intro h1{
    font-size: 28px;
    line-height: 54px;
    max-width: 28ch;
  }

  main .start .intro .intro-points-list{
    font-size: 22px;
    line-height: 35px;
  }

  main .start .intro .completion{font-size: 15px; margin-left: 16px;}


  main .start .intro .completion .cta{
    min-width: 120px;
    max-width: 190px;
    font-size: 18px;
  }
  main .start .intro .completion .time-estimate .clock-icon{width: 20px;height: 20px;}

}

// Tabs CSS


// .tabs {
//   padding: 15px 0px;
//   text-align: center;
//   color: white;
//   width: 100%;
  
//   cursor: pointer;

//   box-sizing: content-box;
//   position: relative;
//   outline: none;
// }
// .active-tabs {
//   color: #000;
//   background: $color1;
//   border-bottom: 1px solid transparent;
//   &::before {
//     content: "";
//     display: block;
//     position: absolute;
//     top: -5px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: calc(100%);
//     height: 0px;
//     background: rgb(88, 147, 241);
//   }
// }
// button {
//   border: none;
// }

